@import "variables";
@import "media";

@mixin container{
	max-width: $container-width;
	width: 100%;
	margin: 0 auto;
	padding-left:20px;
	padding-right:20px;
	left: 0;
	right: 0;
}
@mixin head-container{
	
	width: 100%;
	left: 0;
	right: 0;
}
@mixin header{
	position: absolute;
	z-index: 9;
	width: 100%;
	margin-top: 40px;
}
@mixin section-margin{
	margin-top: 100px!important;
	margin-bottom: 100px!important;
}

@mixin carousel{
	width: 100%;
	height: 100vh;
	display: block;
	position: relative;
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
		filter: alpha(opacity=70);
		opacity: 0.8;
		background-color: #000;
	}
}

@mixin transition($transition:none){
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin blue-shadow($width:100%,$bottom:0, $height: auto){
	position: relative;
	&::before{
		content: url(./images/svg/shadow.svg);
		width: $width;
		height: $height;
		z-index: 0;
		position: absolute;
		bottom: $bottom;
		margin: 0 auto;
		left: 0;
		right: 0;
	}
}

@mixin reference-logo($bgcolor){

	background: $bgcolor;
	width: 180px;
	height: 250px;
	margin-right: 20px;
	float:left;
	box-shadow: 0px 2px 11px rgba(0,0,0,0.3);

	img{
		max-width: 125px;
		
		width: 100%;
		margin: 0 auto;
		display: block;
		max-height: 66px;
		margin-top:20px;
		margin-bottom:20px;
	}

	p{
		color:#fff;
		font-size:12px;
		padding: 15px;
	}
}