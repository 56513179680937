// == SIZES ==
$container-width: 1170px!important;
$header-height: 120px;
$vieport-width: 100vw;
$closer-width: 850px;


// == POSITIONS ==
$block: block;
$inline-block: inline-block;
$inline: inline;
$table: table;
$table-cell: table-cell;
$body-padding: 70px 0px;
$footer-padding: 50px 20px;

// == SHADOWS ==
$box-shadow: 0px 0px 16px rgba(139, 139, 139, 0.5);

