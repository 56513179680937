

.hamburger{
  background:none;

  top:0;
  right:0;
  line-height:45px;
  padding:5px 15px 0px 15px;
  color:$color-blue2;
  border:0;
  font-weight:bold;
  cursor:pointer;
  font-size: 28px;
  outline:none;
  z-index:10000000000000;
}
.cross{
  background:none;
  top:0;
  right:0;
  padding:15px 15px 0px 15px;
  color:$color-blue2;
  border:0;
  font-size: 80px;
      margin-top: 38px;
    line-height: 0px;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  z-index:10000000000000;
}
.menu-burger{
  border-top: 1px solid $color-blue2;
  border-bottom: 1px solid $color-blue2;
  z-index: 1000000;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  background: #fff;
  margin-bottom:0px;
  position: relative;

  left: 0px;
  top: 0px;

  text-align: left;

    margin-top: 50px;
    font-family: "PT Sans",sans-serif !important;
    font-weight: 300;
    margin-top:0px;
    height:auto;
        
}
.menu-burger li {display: block;   padding:6px 0 6px 0;}
.menu-burger li:hover{display: block;    background:#ffffff; }
.menu-burger li a { text-decoration:none;  margin: 0px; color:$color-blue1 !important; font-weight: $strong-font-weight;font-size: 20px;}
.menu-burger li a:hover {  color: $color-blue2; font-weight: $strong-font-weight; text-decoration:none;}
.menu-burger a{text-decoration:none; color:$color-white;}
.menu-burger a:hover{text-decoration:none; color: $color-blue2 !important; font-weight: $strong-font-weight;}

.glyphicon-home{
  color:white; 
  font-size:1.5em; 
  margin-top:5px; 
  margin:0 auto;
}